import { FC, useState } from "react";
import ImageLogoBlack from "../assets/image/logo.svg";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";
import { ConnectWallet } from "./ConnectWallet";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Link } from "react-router-dom";

const Navigation: FC<{ className?: string; buttonClassName: string }> = ({
  className,
  buttonClassName,
}) => {
  const wallet = useAnchorWallet();
  return (
    <>
      <ul className={"sm:flex items-center sm:space-x-4 " + className ?? ""}>
        <li>
          <a className="block p-2 sm:p-0" href="#faq">
            FAQ
          </a>
        </li>
        <li>
          <a className="block p-2 sm:p-0" href="#roadmap">
            Roadmap
          </a>
        </li>
      </ul>
      {!wallet && <ConnectWallet className={buttonClassName} />}
    </>
  );
};

export const Head = () => {
  const [mobileExpanded, setMobileExpanded] = useState(false);

  return (
    <header className="absolute inset-x-0 t-0 z-10">
      <div className="container py-5">
        <div className="flex flex-row justify-between items-center">
          <Link to="/" className="flex flex-row items-center">
            <img
              className="h-10 mr-2"
              src={ImageLogoBlack}
              alt="Mekasol logo"
            />
            <span className="text-lg font-bold text-white">Mekasol</span>
          </Link>
          <div className="flex flex-row items-center">
            <button
              className="p-2 inline-block text-white sm:hidden"
              type="button"
              onClick={() => setMobileExpanded(!mobileExpanded)}
            >
              <Icon path={mdiMenu} size={1} />
            </button>
            <Navigation
              buttonClassName="hidden"
              className="hidden text-white"
            />
          </div>
        </div>
        {/* mobile nav */}
        {mobileExpanded && (
          <Navigation
            buttonClassName="sm:hidden"
            className="bg-black text-white sm:hidden"
          />
        )}
      </div>
    </header>
  );
};
