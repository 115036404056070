import React, { useRef, useEffect, FC } from "react";
// @ts-ignore
import scrollReveal from "scrollreveal";

interface ScrollRevealProps {}

export const ScrollReveal: FC<ScrollRevealProps> = ({ children }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (sectionRef.current)
      scrollReveal().reveal(sectionRef.current, {
        reset: false,
        delay: 500,
      });
  }, []);

  return (
    <div ref={sectionRef} className="scroll-section">
      {children}
    </div>
  );
};
