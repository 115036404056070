import Image1 from "../../assets/image/sample-nfts/1.jpg";
import Image2 from "../../assets/image/sample-nfts/2.jpg";
import Image3 from "../../assets/image/sample-nfts/3.jpg";
import Image4 from "../../assets/image/sample-nfts/4.jpg";
import Image5 from "../../assets/image/sample-nfts/5.jpg";
import Image6 from "../../assets/image/sample-nfts/6.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { SwiperOptions, Autoplay } from "swiper";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const breakpoints: {
  [width: number]: SwiperOptions;
  [ratio: string]: SwiperOptions;
} = {
  640: {
    slidesPerView: 3,
  },
  768: {
    slidesPerView: 3,
  },
  1024: {
    slidesPerView: 5,
  },
};

export const SamplesSection = () => {
  return (
    <section id="samples" className="py-10">
      <Swiper
        modules={[Autoplay]}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        centeredSlides={true}
        grabCursor={true}
        spaceBetween={20}
        loopedSlides={5}
        breakpoints={breakpoints}
        slidesPerView={1}
      >
        {images.map((image, i) => (
          <SwiperSlide key={i} virtualIndex={i}>
            <img src={image} alt="" className="select-none rounded-md mx-auto" />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};
