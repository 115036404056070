import { MintProps } from "../../components/Mint";
import { useInterval, useMedia } from "react-use";
import { FC, useEffect, useRef, useState } from "react";
import BannerVideoH264 from "../../assets/video/banner.h264.compressed.mp4";
import styled from "styled-components";
import BannerStatic from "../../assets/image/banner-static.jpg";

let f = 0;

const HeroVideo = styled.video`
  height: 75vh;
  min-height: 600px;
  width: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
`;

const StaticHeroVideo = styled.div`
  height: 75vh;
  min-height: 600px;
  width: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-image: url(${BannerStatic});
`;

const HeroSection = styled.section`
  position: relative;
  height: 75vh;
  min-height: 600px;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
`;

const ScrollableHeroVideo = () => {
  const heroVideoRef = useRef<HTMLVideoElement>(null);

  const [videoProgress, setVideoProgress] = useState(0);
  const [scrollY, setScrollY] = useState(-1);

  useInterval(() => {
    if (heroVideoRef.current && heroVideoRef.current.duration) {
      const { duration, currentTime } = heroVideoRef.current;

      const targetTime = videoProgress * duration;
      const stepSize = 0.05 * duration;

      let step = 0;
      if (targetTime < currentTime) {
        step = -Math.min(currentTime - targetTime, stepSize);
      } else if (targetTime > currentTime) {
        step = Math.min(targetTime - currentTime, stepSize);
      }

      heroVideoRef.current.currentTime = currentTime + step;
      // f = f + stepSize;
      // heroVideoRef.current.currentTime = f;
      // if (f > duration) {
      //   f = 0;
      // }
    }
  }, 1000 / 30);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (heroVideoRef.current && heroVideoRef.current.duration) {
        const heroHeight = heroVideoRef.current.clientHeight;

        setVideoProgress(Math.min(currentScrollY, heroHeight) / heroHeight);
      }

      if (heroVideoRef.current) {
        heroVideoRef.current.style.transform = `translateY(${
          -currentScrollY * 0.15
        }px)`;
      }
      setScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, {
      passive: false,
      capture: false,
    });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollY]);

  return (
    <HeroVideo ref={heroVideoRef} playsInline loop preload="auto">
      <source
        src={BannerVideoH264}
        type="video/mp4; codecs=avc1.4D401E,mp4a.40.2"
      />
    </HeroVideo>
  );
};

export const Hero: FC = (props) => {
  const isBelowSmall = useMedia("(max-width: 640px)");

  return (
    <HeroSection id="hero">
      {props.children}
      {isBelowSmall ? <StaticHeroVideo /> : <ScrollableHeroVideo />}
    </HeroSection>
  );
};
