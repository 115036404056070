import { ReactNode } from "react";
import { premintDate, socialTwitter, utcDateTimeFormatter } from "../../config";

const FaqLine = ({
  question,
  answer,
}: {
  question?: ReactNode;
  answer?: ReactNode;
}) => {
  return (
    <li className="mb-6">
      {question && <p className="text-white text-lg mb-1">{question}</p>}
      {answer && <p className="text-gray-400">{answer}</p>}
    </li>
  );
};

export const FaqSection = () => {
  return (
    <section id="faq">
      <div className="container lg:w-6/12 py-5 md:py-20">
        <h2 className="text-4xl text-white font-semibold mb-4">FAQ</h2>

        <ul>
          <FaqLine
            question={<>How many Mekasols will be minted?</>}
            answer={<>3939</>}
          />
          <FaqLine
            question={<>When are we minting?</>}
            answer={<>Saturday, December 11th at 7 PM UTC.</>}
          />
          <FaqLine
            question={<>What blockchain will Mekasols are on?</>}
            answer={
              <>
                Solana.
                <br />
                It's fast, reliable and the transaction costs are almost
                non-existent.
              </>
            }
          />
          <FaqLine
            question={<>How much will it cost to mint one Mekasol?</>}
            answer={
              <>
                0.75 SOL for OGs and Whitelist members.
                <br />
                1.5 SOL for the Public Mint.
              </>
            }
          />
          <FaqLine
            question={<>Are there secondary royalty fees?</>}
            answer={
              <>Yep. 5% - which is the usual rate for Solana NFT projects.</>
            }
          />
          <FaqLine
            question={<>Are there giveaways?</>}
            answer={
              <>
                Yes! We'll be giving away Mekasols to our most loyal community
                members! Check out the giveaway section on our website or{" "}
                <a href={socialTwitter}>Twitter</a>.
              </>
            }
          />
        </ul>
      </div>
    </section>
  );
};
