import { ReactNode } from "react";
import styled from "styled-components";

const lineOffset = 50;
const lineOffsetSmall = 20;
const decorationSize = 20;

const RoadmapLine = styled.div`
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 10px,
    rgba(255, 255, 255, 1) 11px,
    rgba(0, 0, 0, 0) 12px,
    rgba(0, 0, 0, 0) 100%
  );
  background-position: ${lineOffsetSmall}px 0;
  padding: 20px 0;

  @media (min-width: 768px) {
    background-position: ${lineOffset}px 0;
  }
`;

const CardDecoration = styled.div`
  padding-left: calc(2.5rem + ${lineOffsetSmall}px);

  &:before {
    content: "";
    position: absolute;
    left: ${lineOffsetSmall + 1}px;
    top: calc(50% - ${decorationSize / 2}px);
    width: ${decorationSize}px;
    height: ${decorationSize}px;
    background: ${({ completed }: { completed: boolean }) =>
      completed
        ? "linear-gradient(90deg, #dc2626, #f87171)"
        : "linear-gradient(90deg, #828282, #b5b5b5)"};
    border-radius: 2px;
    transform: rotate(45deg);
  }

  @media (min-width: 768px) {
    padding-left: calc(2.5rem + ${lineOffset}px);

    &:before {
      left: ${lineOffset + 1}px;
    }
  }
`;

const RoadmapCard = ({
  num,
  title,
  description,
  completed = false,
}: {
  num: number;
  title: string;
  description: ReactNode;
  completed?: boolean;
}) => {
  return (
    <CardDecoration
      completed={completed}
      className="relative px-4 py-5 bg-gray-800 bg-opacity-80 shadow-lg sm:rounded-md sm:p-10"
    >
      <div
        className={
          (completed ? "primary-fade" : "grey-fade") + " inline-block font-bold"
        }
      >
        #{`${num}`.padStart(3, "0")}
      </div>
      <div className="text-2xl font-semibold text-white mb-3">{title}</div>
      <div className="text-base leading-6 space-y-4 text-gray-400 sm:text-lg sm:leading-7">
        {description}
      </div>
    </CardDecoration>
  );
};

export const Roadmap = () => {
  return (
    <section id="roadmap">
      <div className="container lg:w-6/12 py-5 md:py-20">
        <div className="text-4xl text-white font-semibold mt-5 md:mt-0 mb-5 text-center">
          Roadmap
        </div>
        <RoadmapLine className="space-y-6">
          <RoadmapCard
            num={1}
            completed={true}
            title="Website"
            description={<>Great news. It’s ready.</>}
          />
          <RoadmapCard
            num={2}
            completed={true}
            title="Gen1 Mint"
            description={
              <>
                Our Gen1 mint drops Saturday, December 11th at 7 PM UTC.
                <br />
                OG and whitelist members will be able to mint 30 minutes before
                the public mint at a discounted price of 0.75 SOL.
                <br />
                The public mint will start right afterwards at around 7:30 PM
                UTC at a price of 1.5 SOL.
              </>
            }
          />
          <RoadmapCard
            num={3}
            title="Gen0 Auctions"
            description={
              <>
                We will auction off a total of 10 Gen0 Mekasols. These will have
                various benefits over all other Generations and will essentially
                be the Gods of the Mekasol Universe.
                <br />
                There will be five Hero MekaGods and five Villain MekaGods.
                <br />
                <br />
                More information on this soon.
              </>
            }
          />
          <RoadmapCard
            num={4}
            title="Meka Staking"
            description={
              <>
                Stake your Meka to generate Meka Tokens every 24 hours. These
                tokens can be exchanged for exclusive weapons and add-ons for
                your Meka.
                <br />
                <br />
                Details soon.
              </>
            }
          />
          <RoadmapCard
            num={5}
            title="Weapon & Item Mint"
            description={<>This mint runs exclusively on Meka Tokens.</>}
          />
          <RoadmapCard
            num={6}
            title="3D-Printed Figures"
            description={
              <>
                We put in the work to develop the specs and find the best
                printer to create top-quality 3D prints of your Meka.
                <br />
                Take your favorite Mekas from your wallet to the real world by
                ordering through our website.
              </>
            }
          />
          <RoadmapCard
            num={7}
            title="Merch"
            description={
              <>
                We’ve got it. Make a spot in your closet for our exclusive
                top-quality hoodies, available for sale only to unique NFT
                holders.
                <br />
                <br />
                We have many more exciting plans for the project. Stay tuned!
              </>
            }
          />
        </RoadmapLine>
      </div>
    </section>
  );
};
