import { mdiTwitter, mdiDiscord, mdiInstagram } from "@mdi/js";
import Icon from "@mdi/react";
import ImageLogo from "../assets/image/logo.svg";
import ImageHomies from "../assets/image/9homies-logo-white.svg";
import { socialDiscord, socialInsta, socialTwitter } from "../config";

const SocialIcon = ({
  url,
  title,
  icon,
}: {
  url: string;
  title: string;
  icon: string;
}) => {
  return (
    <a
      href={url}
      target="_blank"
      className="rounded-sm inline-block m-2 p-2 text-white border-red-500 border border-solid"
      title={title}
    >
      <Icon path={icon} size={1} />
    </a>
  );
};

const BottomFooter = () => {
  return (
    <div className="md:flex items-center flex-row justify-between">
      <div className="text-gray-400">
        &copy; 2021 Mekasol. All rights reserved
      </div>
      <div>
        <SocialIcon
          url={socialTwitter}
          title="Mekasol NFT on twitter"
          icon={mdiTwitter}
        />
        <SocialIcon
          url={socialDiscord}
          title="Join our discord"
          icon={mdiDiscord}
        />
        <SocialIcon
          url={socialInsta}
          title="Mekasol NFT on instagram"
          icon={mdiInstagram}
        />
      </div>
    </div>
  );
};

const TopFooter = () => {
  return (
    <div className="flex justify-between flex-row mb-5 md:mb-0">
      <div className="text-white">
        <div className="flex items-center mb-2">
          <img className="h-8" src={ImageLogo} alt="Mekasol logo" />
          <span className="text-xl font-semibold ml-2">Mekasol</span>
        </div>
        <p className="text-base">
          Hand modeled 3D assets, algorithmically generated 3939 unique Mekasols
        </p>
      </div>
    </div>
  );
};

const PoweredBy = () => {
  return (
    <>
      <div className="border-solid border-gray-700 border-t m-10" />
      <div className="text-gray-400 mb-5">
        <div className="flex justify-center items-center text-base">
          <span className="mr-2">powered by</span>
          <a href="https://twitter.com/ninehomies" target="_blank">
            <img className="h-4" src={ImageHomies} alt="9HOMIES" />
          </a>
        </div>

        <div className="flex justify-center items-center text-base">
          <span className="ml-2">Your Solana Launchpad</span>
        </div>
      </div>
    </>
  );
};

export const Footer = () => {
  return (
    <footer>
      <div className="container py-5 md:py-20">
        <TopFooter />
        <BottomFooter />
        <PoweredBy />
      </div>
    </footer>
  );
};
