import styled from "styled-components";
import { socialDiscord, socialInsta, socialTwitter } from "../../config";
import Icon from "@mdi/react";
import { mdiDiscord, mdiInstagram, mdiTwitter } from "@mdi/js";

const Section = styled.section``;

const SocialLink = ({
  href,
  title,
  icon,
}: {
  href: string;
  title: string;
  icon: string;
}) => {
  return (
    <a
      href={href}
      target="_blank"
      className="primary-bg text-white font-bold uppercase px-5 py-2 rounded-md flex items-center"
    >
      <Icon path={icon} size={1} className="mr-2" />
      {title}
    </a>
  );
};

export const SocialSection = () => {
  return (
    <Section id="social">
      <div className="container lg:w-6/12 py-5 md:py-20 flex justify-center">
        <div>
          <h2 className="text-4xl text-white font-semibold mb-5 text-center">
            Join the community
          </h2>
          <div className="sm:flex flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <SocialLink
              href={socialTwitter}
              title="Twitter"
              icon={mdiTwitter}
            />
            <SocialLink
              href={socialDiscord}
              title="Discord"
              icon={mdiDiscord}
            />
            <SocialLink
              href={socialInsta}
              title="Instagram"
              icon={mdiInstagram}
            />
          </div>
        </div>
      </div>
    </Section>
  );
};
