import Icon from "@mdi/react";
import { mdiMemory } from "@mdi/js";

export const Separator = ({ className = "" }: { className?: string }) => {
  return (
    <div
      className={
        "container flex flex-row items-center text-red-500 " + className
      }
    >
      <hr className="flex-grow" />
      <Icon
        path={mdiMemory}
        size={1}
        rotate={45}
        className="flex-shrink-0 mx-2"
      />
      <hr className="flex-grow" />
    </div>
  );
};
