export const premintDate = new Date("2021-12-11T19:00:00.000Z");
export const publicMintDate = new Date("2021-12-11T19:30:00.000Z");

export const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  day: "numeric",
  month: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

export const utcDateTimeFormatter = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  day: "numeric",
  month: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  timeZoneName: "short",
  timeZone: "utc",
});

export const socialDiscord = "http://discord.gg/5MExtzq9SH";
export const socialTwitter = "https://www.twitter.com/mekasolnft";
export const socialInsta = "https://instagram.com/mekasolnft";

export const presalePass = "JRK3T9";
export const premintPrice = 0.75;
export const mintPrice = 1.5;
