import { useWalletDialog } from "@solana/wallet-adapter-material-ui";
import { MouseEventHandler, useCallback } from "react";

export const ConnectWallet = ({
  label = "Connect Wallet",
  className = "",
}: {
  label?: string;
  className?: string;
}) => {
  const { setOpen } = useWalletDialog();

  const handleClick: MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      if (!event.defaultPrevented) setOpen(true);
    },
    [setOpen]
  );

  return (
    <button
      role="button"
      className={
        "sm:flex sm:ml-4 py-2 px-4 items-center justify-center rounded-md primary-bg text-white " +
        className
      }
      onClick={handleClick}
    >
      {label}
    </button>
  );
};
