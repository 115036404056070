import { FaqSection } from "./Faq";
import { Separator } from "../../components/Separator";
import { Roadmap } from "./Roadmap";
import { SamplesSection } from "./Samples";
import styled from "styled-components";
import { ScrollReveal } from "../../components/ScrollReveal";
import { SocialSection } from "./Social";
import { useEffect, useRef, useState } from "react";
import { useInterval, useMedia } from "react-use";
import { Countdown } from "../../components/Countdown";

import SummaryH264 from "../../assets/video/summary.mp4";
import BannerVideoH264 from "../../assets/video/banner.h264.compressed.mp4";
import BannerStatic from "../../assets/image/banner-static.jpg";
import { Mint, MintContainer, MintProps } from "../../components/Mint";
import { Hero } from "./Hero";
import { Link } from "react-router-dom";
import { premintDate, publicMintDate } from "../../config";

const SummarySection = () => {
  return (
    <section id="summary">
      <div className="container py-5 md:py-20">
        <div className="md:flex items-center flex-row">
          <div className="md:mr-20">
            <h2 className="text-4xl font-semibold mb-4">
              <div className="text-red-500 primary-fade">
                3939 unique Mekasols
              </div>
              <div className="text-white">waiting for you to take control!</div>
            </h2>
            <div className="text-gray-400">
              <p className="mb-3">
                Mekasol is a PFP NFT project launching on Solana.
              </p>
              <p className="mb-3">
                Each Mekasol is one-of-a-kind and hand-modeled with 600+ 3D
                assets generating over 2.5 million possible combinations.
              </p>
              <p className="mb-3">
                Not to mention exclusive color schemes, variations and
                backgrounds - of which only 3939 will be available for mint.
              </p>
            </div>
          </div>
          <div>
            <video
              className="rounded-md"
              autoPlay={true}
              loop={true}
              muted={true}
              src={SummaryH264}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const SecondaryContent = styled.div`
  background-color: #1b1e20;
`;

const timeToMint = () => {
  const now = Date.now();
  const deltaPremint = premintDate.getTime() - now;
  const deltaPublicMint = publicMintDate.getTime() - now;

  return { deltaPremint, deltaPublicMint };
};

const MintLink = () => {
  const [timeLeft, setTimeLeft] = useState(timeToMint());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(timeToMint());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const isPremint = timeLeft.deltaPublicMint > 0;

  return (
    <MintContainer className="w-10/12 sm:w-auto flex items-center justify-center px-4 py-5 bg-gray-900 shadow-lg sm:rounded-md sm:p-6">
      <Link
        to="/mint"
        className="font-bold sm:flex py-2 px-4 items-center justify-center rounded-md primary-bg text-white"
      >
        {isPremint ? "Premint now" : "Mint now"}
      </Link>
    </MintContainer>
  );
};

export const Home = (props: MintProps) => {
  return (
    <>
      <Hero>
        {false && (
          <Countdown title="PUBLIC MINT DATE" targetDate={publicMintDate}>
            <MintLink />
          </Countdown>
        )}
      </Hero>
      <SummarySection />
      <Separator />
      <SamplesSection />
      <Separator />

      <SocialSection />

      <SecondaryContent>
        <ScrollReveal>
          <Roadmap />
        </ScrollReveal>
        <ScrollReveal>
          <Separator />
          <FaqSection />
        </ScrollReveal>
      </SecondaryContent>
    </>
  );
};
