import { CircularProgress } from "@material-ui/core";
import * as anchor from "@project-serum/anchor";
import { useEffect, useState } from "react";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "../candy-machine";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import {
  mintPrice,
  premintDate,
  premintPrice,
  publicMintDate,
} from "../config";
import { ConnectWallet } from "./ConnectWallet";
import { Separator } from "./Separator";
import styled from "styled-components";
import { useSnackbar } from "notistack";

export interface MintProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
  presale: anchor.web3.PublicKey;
}

export const MintContainer = styled.div`
  background-color: #212529;
`;

export const Mint = (props: MintProps) => {
  const [balance, setBalance] = useState<number>();
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [isOver, setIsOver] = useState(true); // true minting isn't allowed anymore
  const [itemsAvailable, setItemsAvailable] = useState<number>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [mintPriceInSol, setMintPriceInSol] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const isActive = Date.now() > premintDate.getTime();

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          enqueueSnackbar("Congratulations! Mint succeeded!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Mint failed! Please try again!", {
            variant: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(() => {
    (async () => {
      if (!wallet) return;
      console.log("cyndy id" + props.candyMachineId);
      console.log("connection" + props.connection);
      const {
        candyMachine,
        goLiveDate,
        itemsRemaining,
        itemsAvailable,
        price,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setMintPriceInSol(price / LAMPORTS_PER_SOL);
    })();
  }, [wallet, props.candyMachineId, props.connection]);

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(Date.now());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const isBeforeMint = time < premintDate.getTime();
  const isPublicMint = time > publicMintDate.getTime();
  const displayItemsAvailable = true;

  return (
    <MintContainer className="w-10/12 sm:w-auto flex items-center justify-center px-4 py-5 bg-gray-900 shadow-lg sm:rounded-md sm:p-6">
      {!wallet ? (
        <ConnectWallet />
      ) : (
        <div className="space-y-4 w-full">
          <div className="flex justify-center">
            <button
              className="font-bold sm:flex py-2 px-4 items-center justify-center rounded-md primary-bg text-white"
              type="button"
              disabled={isBeforeMint || isSoldOut || isMinting || !isActive}
              onClick={onMint}
            >
              {isSoldOut ? (
                "SOLD OUT"
              ) : isActive ? (
                isMinting ? (
                  <CircularProgress color="inherit" thickness={5} size="16px" />
                ) : (
                  "MINT NOW!"
                )
              ) : (
                "Mint not started yet"
              )}
            </button>
          </div>
          {wallet && isActive && (
            <div className="text-gray-500 text-center text-sm mt-2">
              {shortenAddress(wallet.publicKey.toBase58()) || ""}
            </div>
          )}
          {isActive && (
            <>
              <Separator className="w-4/5" />
              {displayItemsAvailable && wallet && (
                <div className="space-y-4 sm:space-y-0 sm:grid gap-4 grid-cols-2 text-center">
                  <div>
                    <div className="uppercase text-xs font-semibold">Price</div>
                    <div className="font-bold">
                      {isPublicMint ? mintPrice : premintPrice} SOL
                    </div>
                  </div>
                  <div>
                    <div className="uppercase text-xs font-semibold">
                      Available Mekasols
                    </div>
                    <div className="text-center font-bold">
                      {itemsRemaining} / {itemsAvailable}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </MintContainer>
  );
};
